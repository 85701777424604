<template>
	<div>
		<b-row>
			<b-col lg="6" md="6" sm="6" class="heading-title">
				<h4> {{ this.venue_booking_details.booking_type=="1" ?"Booking__" : "Blocking" }}  Summary</h4>
				<b-breadcrumb class="p-0 m-0" id="breadcrumb-heading">
					<b-breadcrumb-item to="/dashboard" custom> Home </b-breadcrumb-item>
					<b-breadcrumb-item to="/my-booking">My Booking</b-breadcrumb-item>
					<b-breadcrumb-item active>{{ this.venue_booking_details.booking_type=="1" ?"Booking" : "Blocking" }}  Summary</b-breadcrumb-item>
				</b-breadcrumb>
			</b-col>
			<b-col lg="6" md="6" sm="6" class="btns-header"> </b-col>
		</b-row>
		<br>
		<b-row>
			
			<b-col lg="12" md="12" sm="12" class="heading-title" ref="msgContainer">
				<b-button @click="$router.go(-1)"  class=" btn-venuecolor btn-sm mb-2 mt-1">
					<i class="mdi mdi-arrow-left "></i> Back
				</b-button>
				&nbsp;
				<b-button class="btn btn-venuecolor btn-sm mb-2 mt-1"  @click="editbooking"><i class="mdi mdi-pencil"></i> Edit Booking</b-button>
				
				<b-card class="mb-4 w-100">
					<b-row class="m-2">
						<b-col lg="12" md="12" sm="12" class="heading-title" ref="msgContainer">
							
							<h3 class="text-center">  {{ this.venue_booking_details.booking_type=="1" ?"Booking" : "Blocking" }}  Summary </h3>
							
							<div class="text-center" v-if=" this.set_all_view_data.invoice_number!=null" style="font-weight: bold;color:green">
								( Invoice Generated )
							</div>
							<div class="d-flex justify-content-between">
								
								<div> 
									Order date : <span class="text-danger fs-4"> {{date_format(booked_on)}}</span>
								</div>
								<div class="mt-2"> 
									
									<div>Booking Ref. No.. <span class="text-danger fs-4">{{ ref_id }}</span></div>
									<div>Booking ID. <span class="text-danger fs-4">{{ Book_id }}</span></div>
									
								</div>
							</div>
							<hr>
							
							<b-row>
								<div class="col-lg-12">
									<table class="w-100">
										<tbody>
											<tr>
												<td><b>Venue Name</b></td>
												<td class="px-3">:</td>
												<td>{{ child_venue_name }}</td>
												
												<td class="text-left"><b>Event Date</b></td>
												<td class="px-3">:</td>
												<td class="text-left">{{ date_format(selected_date) }}</td>
											</tr>
											<tr>
												<td><b>Shift</b></td>
												<td class="px-3">:</td>
												<td>{{ booked_shift_type }} Shift</td>
												<td><b>Timings</b></td>
												<td class="px-3">:</td>
												<td>{{ from_time }} to {{ to_time }}</td>
												
											</tr>
											<tr>
												<td><b>Event type</b></td>
												<td class="px-3">:</td>
												<td><span v-if="event_name != ''">{{ event_name }}</span></td>
												
												<td><b>Guest capacity </b></td>
												<td class="px-3">:</td>
												<td>{{ guest_capacity }}</td>
												
												
											</tr>
											
										</tbody>
									</table>
								</div>
								
							</b-row>	
							<hr>
							
							<b-row>	
								
								<div class="col-lg-8">
									<h4> Customer details</h4>
									<table class="w-100">
										<tbody>
											
											<tr v-if="this.venue_booking_details.booking_for_self == 0" style="background-color:#F5F5F5;">
												<td style="width: 180px;"><b> Representative name</b></td>
												<td  style="width: 10px;" class="px-3">:</td>
												<td  style="width: 180px;">{{ this.venue_booking_details.booking_for_name }}</td>
												
												<td  style="width: 180px;" class="text-left" ><b>Relationship </b></td>
												<td style="width: 10px;" class="px-3">:</td>
												<td  style="width: 180px;" class="text-left">{{ this.venue_booking_details.booking_for_relationship }}</td>
												
												
											</tr>
											<tr v-if="this.venue_booking_details.booking_for_self == 0" style="background-color:#F5F5F5;">
												<td style="width: 180px;" class="text-left"><b>Mobile no</b></td>
												<td style="width: 10px;" class="px-3">:</td>
												<td  style="width: 180px;" class="text-left">{{ this.venue_booking_details.booking_for_mobile_no }}</td>
											</tr>
											<tr>
												<td colspan="9"></td>
											</tr>
											<tr>
												<td style="width: 180px;"><b> Customer Name</b></td>
												<td  style="width: 10px;" class="px-3">:</td>
												<td  style="width: 180px;">{{ billing_first_name }}</td>
												
												<td style="width: 180px;" class="text-left"><b>Email</b></td>
												<td  style="width: 10px;" class="px-3">:</td>
												<td  style="width: 180px;" class="text-left">{{ billing_email }}</td>
												
												
											</tr>
											<tr>
												<td style="width: 180px;" class="text-left"><b>Phone</b></td>
												<td  style="width: 10px;" class="px-3">:</td>
												<td  style="width: 180px;" class="text-left">{{ billing_phone }}</td>
												
												<td style="width: 180px;" class="text-left" v-if="Alternative_number !=null"><b>Alternate Phone No.</b></td>
												<td  style="width: 10px;" class="px-3" v-if="Alternative_number !=null">:</td>
												<td  style="width: 180px;" class="text-left" v-if="Alternative_number !=null">{{ Alternative_number }}</td>
											</tr>
											<tr>
												<td style="width: 180px;"><b>Address</b></td>
												<td  style="width: 10px;" class="px-3">:</td>
												<td  style="width: 180px;">{{ billing_address }} </td>
												
												
												
											</tr>
											
											
										</tbody>
									</table>
									
									
									
									
									<span v-if="informations">
										<hr v-if=" informations.bride_mob!=null || informations.groom_mob!=null || informations.billing_bride_name!=null || informations.billing_bridegroom!=null || informations.billing_bridelocation!=null || informations.billing_bridegroomlocation!=null || informations.billing_caterer!=null || informations.booking_decorator!=null || informations.booking_music!=null || informations.booking_sound!=null">
										<h4 v-if=" informations.bride_mob!=null || informations.groom_mob!=null || informations.billing_bride_name!=null || informations.billing_bridegroom!=null || informations.billing_bridelocation!=null || informations.billing_bridegroomlocation!=null || informations.billing_caterer!=null || informations.booking_decorator!=null || informations.booking_music!=null || informations.booking_sound!=null"  > Information</h4>
										
										
										<table class="w-100" >
											<tbody>
												<tr>
													<td style="width: 180px;" v-if="informations.billing_bride_name!=null"><b> Bride Name</b></td>
													<td  style="width: 10px;" v-if="informations.billing_bride_name!=null" class="px-3">:</td>
													<td  style="width: 180px;" v-if="informations.billing_bride_name!=null">{{ informations.billing_bride_name }}</td>
													
													<td style="width: 180px;" v-if="informations.bride_mob!=null" class="text-left"><b> Bride Mobile no.</b></td>
													<td  style="width: 10px;" v-if="informations.bride_mob!=null" class="px-3">:</td>
													<td style="width: 180px;" v-if="informations.bride_mob!=null" class="text-left">{{ informations.bride_mob }}</td>
													
													
													
												</tr>
												
												<tr>
													<td style="width: 180px;" v-if="informations.billing_bridelocation!=null" class="text-left"><b> Bride City </b></td>
													<td  style="width: 10px;" v-if="informations.billing_bridelocation!=null" class="px-3">:</td>
													<td style="width: 180px;" v-if="informations.billing_bridelocation!=null" class="text-left">{{ informations.billing_bridelocation }}</td>
													
													
													<td style="width: 180px;" v-if="informations.billing_bridegroom!=null" class="text-left"><b> Groom Name</b></td>
													<td style="width: 10px;" v-if="informations.billing_bridegroom!=null" class="px-3">:</td>
													<td style="width: 180px;" v-if="informations.billing_bridegroom!=null" class="text-left">{{ informations.billing_bridegroom }}</td>
												</tr>
												<tr>	
													<td style="width: 180px;" v-if="informations.groom_mob!=null" class="text-left"><b> Groom Mobile no.</b></td>
													<td style="width: 10px;" v-if="informations.groom_mob!=null" class="px-3">:</td>
													<td style="width: 180px;" v-if="informations.groom_mob!=null" class="text-left">{{ informations.groom_mob }}</td>
													
													
													<td style="width: 180px;" v-if="informations.billing_bridegroomlocation!=null " class="text-left"><b>Bridegroom City</b></td>
													<td style="width: 10px;" v-if="informations.billing_bridegroomlocation!=null" class="px-3">:</td>
													<td style="width: 180px;" v-if="informations.billing_bridegroomlocation!=null" class="text-left">{{ informations.billing_bridegroomlocation }} </td>
												</tr>
												
												<tr>	
													<td style="width: 180px;" v-if="informations.billing_caterer!=null" class="text-left"><b> Caterer Name </b></td>
													<td style="width: 10px;" v-if="informations.billing_caterer!=null" class="px-3">:</td>
													<td style="width: 180px;" v-if="informations.billing_caterer!=null" class="text-left">{{ informations.billing_caterer }}</td>
													
													<td style="width: 180px;" v-if="informations.booking_decorator!=null" class="text-left"><b> Decorator Name </b></td>
													<td style="width: 10px;" v-if="informations.booking_decorator!=null" class="px-3">:</td>
													<td style="width: 180px;" v-if="informations.booking_decorator!=null" class="text-left">{{ informations.booking_decorator }}</td>
													
													
													
												</tr>
												
												<tr>
													<td style="width: 180px;" v-if="informations.booking_sound!=null" class="text-left"><b>Sound person Name</b></td>
													<td style="width: 10px;" v-if="informations.booking_sound!=null" class="px-3">:</td>
													<td style="width: 180px;" v-if="informations.booking_sound!=null">{{ informations.booking_sound }} </td>
													
													<td style="width: 180px;" v-if="informations.booking_music!=null" class="text-left"><b>Music person Name</b></td>
													<td style="width: 10px;" v-if="informations.booking_music!=null" class="px-3">:</td>
													<td style="width: 180px;" v-if="informations.booking_music!=null">{{ informations.booking_music }} </td>
													
												</tr>
												
												
											</tbody>
										</table>
									</span>
									
									<hr>
									
									<h4> Add-Ons </h4>
									
									<div class="row">
										<div class="col-md-3 col-xl-3 mb-4" v-for="(addon, index) in add_ons_details" :key="index">
											
											<div class="extradesign">
												<div class="card h-100">
													<div class="bg-label-primary rounded-3 text-center mb-0 pt-0">
														<img class="img-fluid fix-set custom-image-fluid" :src="addon.attachment ? baseUrl+'/'+JSON.parse(addon.attachment) : require('../../assets/images/addon.png') " alt="Card girl image" width="500">
													</div>
													<div class="mb-0 text-center">
														<span  v-if="addon.add_on_name.length<15" class="text-center">{{ addon.add_on_name }}</span>
														<span  class="text-center" v-else>{{ addon.add_on_name.substring(0,15)+".." }}</span> <br>
														<p><i :class="{'mdi':true,[curncy]:true}"></i>{{ Indian_numberWithCommas(addon.price) }} *{{ addon.qty }} <i :class="{'mdi':true,[curncy]:true}"></i>{{ Indian_numberWithCommas(addon.total) }} </p>
														
													</div>
													
												</div>
											</div>
										</div>
										
										
									</div>
								</div>
								<div class="col-md-4" style="border-left:1px solid mediumorchid">
									<h4 class="text-left p-3" style="border-bottom:5px solid mediumorchid"> Pricing Summary</h4>
									<ul class="custom-amount-summary_1">
										<li class="mt-2 mb-2">
											<div class="row mt-1 mb-1">
												<div class="col-md-8 text-left "> 
													Base Price
												</div>
												<div class="col-md-1 fw-bold text-right" > 
													<i :class="{'mdi':true,[curncy]:true,'left_amount_fix':true}"></i>
												</div>
												<div class="col-md-3 fw-bold text-right" > 
													{{  Indian_numberWithCommas( autocalculation(total_booking_price)) }}
												</div>
											</div>	
										</li>
										
										<li class="mt-2 mb-2">
											<div class="row mt-1 mb-1">
												<div class="col-md-8 text-left "> 
													<b>Add-on :</b> 
												</div>
												
											</div>	
										</li>
										<li v-for="(addon, index) in add_ons_details" :key="index" class="mt-2 mb-2">
									<div class="row mt-1 mb-1">
										<div class="col-md-5 text-left "> 
											{{ addon.add_on_name }} 
										</div>
										<div class="col-md-3 fw-bold text-right" > 
												<i :class="{'mdi':true,[curncy]:true}"></i>{{ Indian_numberWithCommas(addon.price) }} *{{ addon.qty }}
												
											</div>
										<div class="col-md-1 fw-bold text-right" > 
											<i :class="{'mdi':true,[curncy]:true,'left_amount_fix':true}"></i>
													</div>
										<div class="col-md-3 text-right"  > 
											{{ Indian_numberWithCommas(addon.total) }}
										</div>
									</div>	

								</li>
								<li class="mt-2 mb-2" v-if="addOn_total">
									<div class="row mt-1 mb-1">
										<div class="col-md-8 text-left "> 
											
										</div>
										<div class="col-md-1 fw-bold text-right" > 
													<i :class="{'mdi':true,[curncy]:true,'left_amount_fix':true}"></i>
												</div>
												<div class="col-md-3 text-right" style="border-top: 1px solid black;" > 
													{{ Indian_numberWithCommas(addOn_total) }}
												</div>
									</div>	

								</li>
										<hr class="summary-hr">
										<li class="mt-2 mb-2">
											<div class="row mt-1 mb-1">
												<div class="col-md-8 text-left "> 
													<b> Total Base price</b> 
												</div>
												<div class="col-md-1 fw-bold text-right" > 
													<i :class="{'mdi':true,[curncy]:true,'left_amount_fix':true}"></i>
												</div>
												<div class="col-md-3 text-right" > 
													
													{{ Indian_numberWithCommas(add_sub_total) }}
													
												</div>
											</div>	
										</li>
										<li class="mt-2 mb-2">
											<div class="row mt-1 mb-1">
												<div class="col-md-8 text-left "> 
													Discount Applied({{ this.venue_booking_details.applied_discount/total_booking_price*100 }}%)
												</div>
												<div class="col-md-1 fw-bold text-right" > 
													<i :class="{'mdi':true,[curncy]:true,'left_amount_fix':true}"></i>
												</div>
												<div class="col-md-3  text-right"> 
													<span v-if="this.venue_booking_details.applied_discount>0">
														{{ Indian_numberWithCommas(this.venue_booking_details.applied_discount) }}
													</span>
													<span v-else>
														0
													</span>
												</div>
											</div>	
										</li> 
										<hr class="summary-hr">
										<li class="mt-2 mb-2">
											<div class="row mt-1 mb-1">
												<div class="col-md-8 text-left "> 
													<b>Sub total</b>
												</div>
												<div class="col-md-1 fw-bold text-right" > 
													<i :class="{'mdi':true,[curncy]:true,'left_amount_fix':true}"></i>
												</div>
												<div  class="col-md-3 text-right"> 
													
													{{Indian_numberWithCommas( after_discount) }}
													
												</div>
											</div>	
										</li>
										<li class="mt-2 mb-2">
											<div class="row mt-1 mb-1">
												<div class="col-md-8 text-left "> 
													GST {{this.venue_booking_details.tax_percentage}}% 
												</div>
												<div class="col-md-1 fw-bold text-right" > 
													<i :class="{'mdi':true,[curncy]:true,'left_amount_fix':true}"></i>
												</div>
												<div class="text-right col-md-3"> 
													{{ Indian_numberWithCommas(Math.round(afterTax)) }}
												</div>
											</div>	
										</li>
										<hr class="summary-hr">
										<li class="mt-2 mb-2">
											<div class="row mt-1 mb-1">
												<div class="col-md-8 text-left "> 
													Total
												</div>
												<div class="col-md-1 fw-bold text-right" > 
													<i :class="{'mdi':true,[curncy]:true,'left_amount_fix':true}"></i>
												</div>
												<div class="col-md-3 fw-bold fs-4  text-right"> 
													<span >
														{{ Indian_numberWithCommas(Math.round(total_amount)) }}
													</span>
												</div>
											</div>	
										</li>
										
										<li class="mt-2 mb-2">
											<div class="row mt-1 mb-1">
												<div class="col-md-8 text-left"> 
													Security Deposit
													<div v-if="venue_booking_details.refund_security_policy==true">
														<i class="fs-11 text-danger">(Refundable Deposit)</i>
													</div>
												</div>
												<div class="col-md-1 fw-bold text-right"> 
													<i :class="{'mdi':true,[curncy]:true,'left_amount_fix':true}"></i>
												</div>
												<div class="col-md-3 fw-bold fs-4  text-right"> 
													<span class="">
														{{Indian_numberWithCommas(this.venue_booking_details.security_amount)}}
													</span>
												</div>
											</div>	
										</li> 
										<hr class="summary-hr" >
										<li class="mt-2 mb-2">
											<div class="row mt-1 mb-1">
												<div class="col-md-8 text-left"> 
													Booking Total
												</div>
												<div class="col-md-1 fw-bold text-right" > 
													<i :class="{'mdi':true,[curncy]:true,'left_amount_fix':true}"></i>
												</div>
												<div class="col-md-3 fw-bold fs-4 text-success text-right" > 
													<span class="">
														{{ Indian_numberWithCommas(Math.round(full_total_amount))}}
													</span>
												</div>
											</div>	
										</li>
										<li class="mt-2 mb-2">
											<div class="row mt-1 mb-1">
												<div class="col-md-8 text-left">
													Paid Amount ( {{ this.venue_booking_details.booking_type=="1" ?"Advance" : "Block Amount" }} )
													
												</div>
												<div class="col-md-1 fw-bold text-right" > 
													<!-- <i class="mdi mdi-currency-inr left_amount_fix"></i> -->
													<i :class="{'mdi':true,[curncy]:true,'left_amount_fix':true}"></i>
												</div>
												<div class="col-md-3 fw-bold fs-4 text-success text-right" > 
													<span class="">
														{{ Indian_numberWithCommas(Math.round(this.venue_booking_details.paid_price))}}
													</span>
												</div>
											</div>	
										</li>
										<hr class="summary-hr" >
										<li class="mt-2 mb-2">
											<div class="row mt-1 mb-1">
												<div class="col-md-8 text-left"> 
													Payable(<span :class="paid_status=='Paid'? 'text-success' : 'text-danger'">{{ paid_status }}</span>)
												</div>
												<div class="col-md-1 fw-bold text-right" > 
													<i :class="{'mdi':true,[curncy]:true,'left_amount_fix':true}"></i>
												</div>
												<div class="col-md-3 fw-bold fs-4 text-success text-right" > 
													<span class="" v-if="this.full_total_amount-this.venue_booking_details.paid_price>0">
														{{ Indian_numberWithCommas(this.full_total_amount-this.venue_booking_details.paid_price)}}
													</span>
													<span v-else>
														0
													</span>
												</div>
											</div>	
										</li>
										
									</ul>
									Payment mode : {{ this.makeUpperCase(this.venue_booking_details.payment_mode) }}
									
									<div v-if="this.special_request != null">
							<h5>Special Requests</h5>
							<p v-html="this.special_request"></p>
						</div>
								
								</div>
								
							</b-row>
							
						<hr>
						
					</b-col>
				</b-row>
					</b-card>
				
				</b-col>
				</b-row>
			<br />
			
		</div>
	</template>
	<script>
		import axios from "axios";
		import helpers from '../../mixins/helpers'; 
		import { mapState } from "vuex";
		
		export default {
			name: "BookingDetails",
			mixins: [helpers],
			data: () => ({
				title: "BookingDetails",
				vendor_name: "",
				vendor_id: "",
				venue_name: "",
				booked_on: "",
				from_date: "",
				to_date: "",
				selected_date: "",
				from_time: "",
				event_name: "",
				booked_shift_type: "",
				to_time: "",
				total_booking_price: "",
				child_venue_name: "",
				add_on: [],
				personal_phone: "",
				personal_email: "",
				customer_name: "",
				billing_first_name: "",
				billing_email: "",
				Alternative_number: "",
				billing_address: "",
				billing_phone: "",
				cancel_time: "",
				cancel_date: "",
				cancel_reason: "",
				special_request:'',
				child_venue_id:'',
				Book_id:'',
				ref_id:'',
				informations:'',
				guest_capacity:'',
				addOn_total:0,
				add_sub_total:0,
				total_amount:0,
				full_total_amount:0,
				curncy:"",
				taxRequired:"",
				baseUrl:"",
				paid_status:"",
				paid_price:0,
				child_venue_arr:{
					addons_arr:[],
					attachment_path:'',
				},
				venue_booking_details:[],
				add_ons_details:[],
				addon_amount:0,
				permission_access:'',
				refund_security_policy:'',
				taxpers:0,
			}),
			
			methods: {
				
				booking_details() {
					var booking_id = this.$route.params.id;
					axios
					.post("/admin/get_booking_detail", {
						booking_id: booking_id,
					})
					.then((resp) => {
						if (resp.data.status_code == 200) {
							if (resp.data.status) {
								var data = resp.data.booking_detail;
								this.venue_booking_details = data[0];
								this.vendor_name = data[0].child_venue.get_vendor.vendor_name;
								this.vendor_id = data[0].child_venue.get_vendor.vendor_id;
								this.booked_on = data[0].booked_on;
								this.from_date = data[0].from_date;
								this.selected_date = data[0].selected_date;
								this.Book_id = data[0].auto_increment;
								this.ref_id = data[0].booking_auto_id;
								this.guest_capacity = data[0].guest_capacity;
								this.informations = JSON.parse(data[0].informations);
								this.to_date = data[0].to_date;
								
								if(data[0].booked_shift_type==1)
								{
									this.booked_shift_type = "Morning" ;
								}else if(data[0].booked_shift_type==2)
								{
									this.booked_shift_type = "Afternoon" ;
								}else if(data[0].booked_shift_type==3)
								{
									this.booked_shift_type = "Evening" ;
								}
								this.from_time = data[0].from_time;
								this.event_name = data[0].event_name;
								this.to_time = data[0].to_time;
								this.total_booking_price = data[0].total_booking_price;
								this.child_venue_name = data[0].child_venue.child_venue_name;
								//this.personal_phone = data[0].get_customer.phone;
								//this.personal_email = data[0].get_customer.email;
								//this.customer_name = data[0].get_customer.customer_name;
								this.billing_first_name = data[0].billing_first_name;
								this.billing_email = data[0].billing_email;
								this.billing_address = data[0].billing_address;
								this.billing_phone = data[0].billing_phone;
								this.Alternative_number = data[0].billing_alt_phone;
								this.cancel_time = data[0].cancel_time;
								this.cancel_date = data[0].cancel_date;
								this.cancel_reason = data[0].cancel_reason;
								this.special_request = data[0].special_request;
								this.child_venue_id = data[0].child_venue_id;
								this.add_ons_details = data[0].add_ons;
								this.taxpers = data[0].tax_percentage;
								this.paid_price = data[0].paid_price;
								this.refund_security_policy = data[0].refund_security_policy;
								
								
								
								/* if (data[0].add_on_details !== null) {
									this.add_on = JSON.parse(data[0].add_on_details);
									//this.getChildVenueDetails();
								}  */
								} else {
								// this.$router.push('')
							}
							
						}
					});
				},
				goBack() {
					this.$router.push("/my-booking");
				},
				getChildVenueDetails(){
					axios.post("/api/get_venue_details", {
						child_venue_id: this.child_venue_id,
					})
					.then((resp) => {
						if (resp.data.status_code == 200 && resp.data.status) {
							this.child_venue_arr.addons_arr = resp.data.child_venue_details[0].add_ons;
							this.child_venue_arr.attachment_path = resp.data.child_venue_details[0].attachment_path;
						}
					});
				},
				editbooking()
				{
					var booking_id = this.$route.params.id;
					this.$router.push("/edit-booking/"+booking_id+"/Edit");
				},
				date_format:function(date)
				{
					var d1 = new Date(date);
					var ms = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
					return d1.getDate() + '' + this.getDateOrdinal(d1) +' ' + ms[d1.getMonth()] + ' ' + d1.getFullYear();
				},
				getDateOrdinal(date) {
					let day = date.getDate();
					if (day > 3 && day < 21) return 'th';
					switch (day % 10) {
						case 1:  return "st";
						case 2:  return "nd";
						case 3:  return "rd";
						default: return "th";
					}
				},
				convert_24_12hour(timeString)
				{
					const [hourString, minute] = timeString.split(":");
					const hour = +hourString % 24;
					return (hour % 12 || 12) + ":" + minute + (hour < 12 ? "AM" : "PM");
				},
				Indian_numberWithCommas(x) {
					if(x)
					{
						return x.toString().split('.')[0].length > 3 ? x.toString().substring(0,x.toString().split('.')[0].length-3).replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + x.toString().substring(x.toString().split('.')[0].length-3): x.toString();
					} 
				},
				
				autocalculation(amount)
				{
					this.taxper =this.taxpers+'%';
					let add_amount=0;
					if(this.add_ons_details.length>0)
					{
						for(var i=0;i<this.add_ons_details.length;i++)
						{
							var tamt = this.add_ons_details[i].qty*this.add_ons_details[i].price;
							add_amount+=tamt;
						}
					}
					else
					{
						add_amount=0;
					}
					this.addOn_total=Math.round(add_amount);
					this.add_sub_total=Math.round(add_amount+amount);
					
					if(this.venue_booking_details.applied_discount != '' && this.venue_booking_details.applied_discount >= 0 && amount >= this.venue_booking_details.applied_discount){
						
						this.after_discount = parseInt(this.add_sub_total) - parseInt(this.venue_booking_details.applied_discount);
						
					}
					else
					{
						this.after_discount = Math.round(parseInt(this.add_sub_total));
					}
					this.afterTax=Math.round(this.after_discount*this.taxpers/100);
					
					
					
					this.total_amount=Math.round(this.after_discount+this.afterTax); //inclusive tax
					
					/* if(this.venue_booking_details.secrity_allow==true)
						{
						this.full_total_amount=parseInt(this.total_amount)+parseInt(this.venue_booking_details.security_amount);
						}
						else
						{
						this.full_total_amount=parseInt(this.total_amount);
					}  */
					this.full_total_amount=Math.round(parseInt(this.total_amount)+parseInt(this.venue_booking_details.security_amount));
					
					if(this.paid_price<=this.full_total_amount)
					{
						this.paid_status="Paid";
						
					}
					else
					{
						this.paid_status="Pending";
					}
					return Math.round(amount);
				},
				vendor_check()
				{
					axios.get("/api/get_profile").then((resp) => {
						if (resp.data.status_code == 200) {
							if (resp.data.status) {
								this.curncy= resp.data.users.currencys;
								if(resp.data.users.gst_no==null)
								{
									this.taxRequired='no';
								}
								else
								{
									this.taxRequired='yes';
								}
							}
						}
					});
				},
				autoLoad()
				{
					
					this.venue_booking_details.booking_type = this.set_all_view_data.booking_type =='full' ? 1 : 0;
					//this.venue_booking_details = this.set_all_view_data;
					this.vendor_name = this.set_all_view_data.vendor_name;
					this.Book_id = this.set_all_view_data.auto_increment;
					this.vendor_id = this.set_all_view_data.vendor_id;
					
					this.from_date = this.set_all_view_data.from_date;
					this.selected_date = this.set_all_view_data.from_dates;
					
					this.ref_id = this.set_all_view_data.booking_auto_id;
					this.guest_capacity = this.set_all_view_data.guest_capacity;
					this.informations = JSON.parse(this.set_all_view_data.informations);
					this.to_date = this.set_all_view_data.to_date;
					
					if(this.set_all_view_data.booked_shift_type==1)
					{
						this.booked_shift_type = "Morning" ;
					}else if(this.set_all_view_data.booked_shift_type==2)
					{
						this.booked_shift_type = "Afternoon" ;
					}else if(this.set_all_view_data.booked_shift_type==3)
					{
						this.booked_shift_type = "Evening" ;
					}
					this.from_time = this.set_all_view_data.from_times;
					this.event_name = this.set_all_view_data.event_name;
					this.to_time = this.set_all_view_data.to_times;
					this.total_booking_price = Math.round(this.set_all_view_data.total_booking_price);
					this.child_venue_name = this.set_all_view_data.child_venue_name;
					//this.personal_phone = this.set_all_view_data.get_customer.phone;
					//this.personal_email = this.set_all_view_data.get_customer.email;
					//this.customer_name = this.set_all_view_data.get_customer.customer_name;
					this.billing_first_name = this.set_all_view_data.billing_first_name;
					this.billing_email = this.set_all_view_data.billing_email;
					this.billing_address = this.set_all_view_data.billing_address;
					this.Alternative_number = this.set_all_view_data.billing_alt_phone;
					this.billing_phone = this.set_all_view_data.billing_phone;
					this.cancel_time = this.set_all_view_data.cancel_time;
					this.cancel_date = this.set_all_view_data.cancel_date;
					this.cancel_reason = this.set_all_view_data.cancel_reason;
					this.special_request = this.set_all_view_data.special_request;
					this.child_venue_id = this.set_all_view_data.child_venue_id;
					this.booked_on = this.set_all_view_data.booked_ons;
					this.invoice_number = this.set_all_view_data.invoice_number;
					this.add_ons_details = [];
				},
				access_permission()
				{
					axios
					.post("/api/access_permission_all").then((resp) => {
						for(var i=0; i< resp.data.length;i++)
						{
							if(resp.data[i].subname=='Create Booking' )
							{
								this.permission_access= resp.data[i];
							}
							
							
						}
					});
				},
				
				/* getProfile() {
					axios.get("/api/get_profile").then((resp) => {
					if (resp.data.status_code == 200) {
					this.curncy= resp.data.users.currencys;
					}
					});
				}, */
			},
			mounted() {
				//this.autoLoad();
				this.booking_details();
				this.vendor_check();
				this.access_permission();
				
				//	this.getProfile();process.env.VUE_APP_APIURL;
				this.baseUrl=process.env.VUE_APP_APIURL
				document.documentElement.scrollTop = 0;
			},
			computed: {
				...mapState(["set_all_view_data"]),
				
			},
		};
	</script>
	<style scoped>
		.custom-image-fluid
		{
		max-height: 100px;
		-o-object-fit: cover;
		min-height: 50px;
		object-fit: cover;
		}
		.custom-amount-summary
		{
		list-style-type: none;
		margin-left: -40px;
		}
		.fs-12
		{
		font-size: 10px;
		}
		.extradesign
		{
		padding: 4px 4px 2px;
		box-shadow: 0px 0px 5px 0px;
		border-radius: 7px;
		/* height: 50px; */
		margin-bottom: 11px;
		}
		
		.ml--3
		{
		margin-left: -3%;
		}.ml--7
		{
		margin-left: -7%;
		}
		.mt--3
		{
		margin-top: -3%;
		}
	</style>		